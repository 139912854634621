<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
    custom-class="emails"
    center
    top="10vh"
    append-to-body
    :close-on-click-modal="false"
    v-on="$listeners">
    <p class="title">Email Nomination</p>
    <div class="email-block">
      <div
        v-for="(item,index) in emails"
        :key="index">
        <el-input
          v-model="item.email"
          size="small"
          placeholder="email">
        </el-input>
        <el-button
          type="danger"
          round
          size="small"
          :disabled="emails.length===1"
          @click="removeEmail(index)">
          Remove
        </el-button>
      </div>
      <el-button
        style="margin-top: 5px"
        round
        size="small"
        @click="addEmail">
        + Add more reciepient
      </el-button>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button
      style="width: 180px"
      type="primary"
      round
      @click="sendEmails">
      {{ $t('send') }}
    </el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Emails',
  props: {
    nominations: {
      type: Object,
      required: true
    },
    orderId: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data () {
    return {
      dialogVisible: false,
      emails: [{
        email: ''
      }]
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  created () {
    try {
      const emails = JSON.parse(this.nominations.nominationEmails)
      this.emails = emails.map(item => {
        return {
          email: item
        }
      })
    } catch (e) {
    }
  },
  mounted () {
    this.dialogVisible = true
  },
  methods: {
    addEmail () {
      this.emails.push({ email: '' })
    },

    removeEmail (index) {
      this.emails.splice(index, 1)
    },

    /**
     * 发送邮件
     */
    sendEmails () {
      const emails = this.emails.filter(item => !!item.email).map(item => item.email)
      const data = { ...this.nominations, nominationEmails: JSON.stringify(emails) }
      this.$request.post({
        url: this.$apis.sendEmailInfo,
        data: {
          ...data,
          bargeInfo: JSON.stringify(this.nominations.bargeInfo),
          orderId: this.orderId
        }
      }).then(res => {
        if (res?.code === 1000) {
          this.$emit('update:nominations', data)
          this.$message.success('Emails sent successfully')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.emails {
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .email-block {
    padding: 0 30px;

    > p {
      font-weight: bold;
    }

    > div {
      display: flex;
      margin-bottom: 10px;

      .el-button {
        margin-left: 20px;
      }
    }
  }
}

/deep/ .el-tabs {
  .el-tabs__header {
    margin-left: 30px;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }
}
</style>
